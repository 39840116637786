// Essa classe é utilizada somente para fazer as miniaturas - bloco da miniatura (exibição)
.box-laterais{
	vertical-align: top;
    position: relative;
    margin: 0 -1rem;
}
.bloco {
	display: inline-block;
	width: 25%;
	vertical-align: top;
	padding: 1rem;
	@include border-box;
}

/*
	LARGE SCREEN 
	max-width: 1370px
	min-width: 1201px
*/
@mixin bloco--large(){
}
/* 
	MEDIUM SCREEN 
	max-width: 1200px
	min-width: 1024px
*/
@mixin bloco--medium(){
	
}
/*
	TABLET LANDSCAPE 
	max-width: 1023px
	min-width: 768px
*/
@mixin bloco--tablet-landscape(){
	.box-laterais {
		margin: auto;
	}

	.bloco{
		padding: 1rem;
		width: 100%;
	}
}
/*
	TABLET PORTRAIT 
	max-width: 767px
	min-width: 641px
*/
@mixin bloco--tablet-portrait(){
	.box-laterais {
		margin: auto;
	}

	.bloco{
		padding: 1rem;
		width: 100%;
	}
}
/*
	SMARTPHONE LANDSCAPE
	max-width: 640px
	min-width: 482px
*/
@mixin bloco--smart-landscape(){
	.box-laterais {
		margin: auto;
	}

	.bloco{
		padding: 1rem;
		width: 100%;
	}
}
/*
	SMARTPHONE PORTRAIT
	max-width: 481px
	min-width: 321px
*/
@mixin bloco--smart-portrait(){
	.box-laterais {
		margin: auto;
	}

	.bloco{
		padding: 1rem;
		width: 100%;
	}
}
/*
	OLD SMARTPHONE
	max-width: 320px
*/
@mixin bloco--smart-old(){
	.box-laterais {
		margin: auto;
	}

	.bloco{
		padding: 1rem;
		width: 100%;
	}
}