.header1{
	background-color: white;
	width: 100%;
	z-index: 99999999;
}

.box_header{
	box-shadow: 0 0 20px 1px rgba(black, .05);
	z-index: 999;
	position: relative;
}

.header2{
	background-color: $colorPrimary;
	width: 100%;
	z-index: 99999999;
}
	
.menuacao_btn{
	background-color: $colorPrimary !important;
	color: white !important;
	margin: 0%;
}

.header3{
	width: 100%;
	animation: header3pc .45s ease-in-out;
	animation-fill-mode: forwards;
	transform-origin: center center;
	z-index: 99999999;
}

@keyframes header3pc{
	0% {
		//background-image: linear-gradient(to bottom right, #00174900, #03428200);
		background-color: transparent;
	}
	100% {
		//background-image: linear-gradient(to bottom right, rgba($colorPrimary, 1), rgba($colorSecondary, 1));
		background-color: $colorPrimary;
	}
}

.headerinterno {
	height: 5rem;
	z-index: 1;
}


#sub_menu_lancamentos, #sub_menu_vendas{
	display: none;
	position: absolute;
	left: 0;
	right: 0;
	top: 8.7rem;
	background-image: linear-gradient(to bottom right, $colorPrimary, $colorSecondary);
	box-shadow: 7px 7px 10px rgba(black, .4);
	border-radius: 0 0 .5rem .5rem;
	& .elementos_sub_menu{
		text-align: left;
		overflow: auto;
		max-height: 30rem;
		& p{
			font-size: 1.1rem !important;
			font-weight: 400;
			background-image: none;
			padding: 1.2rem 1.5rem;
			margin: 0;
			border-radius: 0 0 .5rem .5rem;
			transition: all .25s;
			border-left: solid 3px transparent;
			&:hover{
				background-image: linear-gradient(to bottom left, transparent 30%, $colorSecondary);
				border-left: solid 3px white;
				padding-left: 2rem;
			}
		}
		& .titletipo{
			color: white;
			font-weight: 600;
			line-height: 1.3em;
		}

		& .itemtipo{
			color: white !important;
			line-height: 1.3em;
			&:hover{
				font-weight: 600;
			}
		}
	}
}

.headerpc {
	display: block;
	width: 100%;
	position: relative;

	& .superior {
		display: block;
		width: 100%;
		z-index: 3;

		/* GRUPO HEADER */
		& .logo {
			display: inline-block;
			margin-left: 0;
			@include border-box;
			vertical-align: middle;
			width: 12%;
			padding: .5rem 0;
			& .img{
				width: 100%;
				display: block;
				
				& img {
					display: block;
					width: 100%;
				}
			}
		}

		& .busca_header{
			display: inline-block;
			vertical-align: middle;
			width: 25%;
			padding: 0 0 0 8rem;
			position: relative;

			& .btn_busca_home{
				& p{
					user-select: none;
					width: 100%;
					text-align: center;
					color: white;
					font-weight: 600;
					font-size: 1.1rem;
					background-color: $colorPrimary;
					border-radius: .1rem;
					padding: .5rem 0;
					margin: 0 !important;
					&:hover{
						background-color: darken($colorPrimary, 5);
					}
				}
			}
			
		}

		& .sessions {
			display: inline-block;
			width: 58%;
			vertical-align: middle;
			& ul {
				flex: 0 0 0 100%;
				display: flex;
				align-items: center;
				height: 70px;
				margin: 0%;
				padding: 0%;
				list-style: none;
				justify-content: flex-end;
				transition: all .25s ease;
				will-change: height;
				& .itens_header{
					display: inline-block;
					padding: 0 20px;
					& a{
						justify-content: space-between;
						color: $gray1;
						font-weight: 500;
						font-size: 1rem;
						transition: all .2s;
						&:hover{
							color: $colorPrimary;
						}
					}
				}
				& .itens_header2{
					display: inline-block;
					padding: 0 20px;
					& a{
						justify-content: space-between;
						color: white;
						font-weight: 600;
						font-size: 1rem;
						padding: .5rem 1rem;
						background-color: $colorPrimary;
						border-radius: .1rem;
						&:hover{
							background-color: darken($colorPrimary, 5);
						}
					}
				}
			}
		}

		& .menu {
			text-align: right;
			display: inline-block;
			width: 5%;
			vertical-align: middle;
		}

	}
}

.headerindex {
	display: block;
	width: 100%;
	padding: 0;
	@include border-box;
	z-index: 2;
}

.header-mob{
	background-color: white;
	padding: .5rem 0 0 0;
	& .box_logo_mobile{
		width: 40%;
		display: inline-block;
		vertical-align: middle;
		& .log{
			display: inline-block;
			vertical-align: middle;
			width: 7rem;
			& img{
				width: 100%;
			}
		}
	}

	& .box_btn_menu_mobile{
		width: 60%;
		display: inline-block;
		vertical-align: middle;
		text-align: right;

		& .btn_busca_home{
			display: inline-block;
			vertical-align: middle;
			width: 70%;
			& p{
				user-select: none;
				width: 100%;
				text-align: center;
				color: white;
				font-weight: 600;
				font-size: 1rem;
				background-color: $colorPrimary;
				border-radius: .1rem;
				padding: .3rem .5rem;
				margin-right: 0 !important;
			}
		}
		& .btn-men{
			display: inline-block;
			vertical-align: middle;
			width: 30%;
		}
	}
}

#busca_header{
	position: relative;
	display: block;
	width: 100%;
	transition: all 0.2s;
    z-index: 99999;
	& .busca_header_class{
		position: absolute;
		top: 0;
		left: 50%;
		background-color: white;
		display: block;
		width: 65%;
		padding: 1rem;
		text-align: center;
		border: solid 1px $gray4;
		box-shadow: 0 0 10px 1px rgba(black, .1);
		border-radius: .1rem;
		z-index: 999;
		transform: translate(-50%, 0%);
		& .btns_tipo_busca{
			display: inline-block;
			vertical-align: middle;
			width: 20%;
			padding-right: 2rem;
			& .botoes{
				display: block;
				width: 100%;
				& p{
					font-weight: 600;
					margin: 0;
				}
				& button{
					width: 100%;
					display: inline-block;
					background-color: white;
					border: solid 1px $gray4;
					border-radius: .1rem;
					font-size: 1.5rem;
					color: $colorPrimary;
					font-weight: 600;
					padding: .5rem;
					margin: 0;
					cursor: pointer;
					transition: all .15s;
				}
			}
		}

		& .busca-filtro{
			width: 80%;
			display: inline-block;
			vertical-align: middle;
			& .bloco_busca{
				padding: 0;
				margin: 0;
				text-align: center;
				display: block;
				width: 100%;
				& .filtros_busca_home{
					width: 40%;
					display: inline-block;
					& p{
						font-weight: 600;
						margin: 0;
					}
				}
				& .filtros_busca_home2{
					width: 40%;
					display: inline-block;
					& p{
						color: $gray2;
						font-weight: 600;
						margin: 0;
					}
				}
				& .filtros_busca_home_input{
					width: 20%;
					display: inline-block;
					
					& p{
						color: $gray2;
						font-weight: 600;
						margin: 0;
					}
					& .btn-home{
						padding: 0.5rem;
						border-radius: 0.2rem;
						width: 100%;
						display: inline-block;
						vertical-align: middle;
						background-color: $colorPrimary;
						color: white;
						border: solid 1px #C3C3C3;
						font-size: 1rem;
						font-weight: 600;
						cursor: pointer;
						transition: all 0.25s;
						&:hover{
							background-color: darken($colorPrimary, 5);
						}
					}
				}
				& .filtros_busca_home_input2{
					width: 20%;
					display: inline-block;
					& p{
						color: $gray2;
						font-weight: 600;
						margin: 0;
					}
				}
				
			}
		}
	}
}

/*
	LARGE SCREEN 
	max-width: 1370px
	min-width: 1201px
*/
@mixin header--large(){
}
/* 
	MEDIUM SCREEN 
	max-width: 1200px
	min-width: 1024px
*/
@mixin header--medium(){
}
/*
	TABLET LANDSCAPE 
	max-width: 1023px
	min-width: 768px
*/
@mixin header--tablet-landscape(){
}
/*
	TABLET PORTRAIT 
	max-width: 767px
	min-width: 641px
*/
@mixin header--tablet-portrait(){
}
/*
	SMARTPHONE LANDSCAPE
	max-width: 640px
	min-width: 482px
*/
@mixin header--smart-landscape(){
}
/*
	SMARTPHONE PORTRAIT
	max-width: 481px
	min-width: 321px
*/
@mixin header--smart-portrait(){
}
/*
	OLD SMARTPHONE
	max-width: 320px
*/
@mixin header--smart-old(){
}

@media all and(min-width: 1024px) and(max-width: 1366px){

	#busca_header{
		position: relative;
		display: block;
		width: 100%;
		transition: all 0.2s;
  		z-index: 99999;
		& .busca_header_class{
			position: absolute;
			top: 0;
			left: 50%;
			background-color: white;
			display: block;
			width: 80%;
			padding: 1rem;
			text-align: center;
			border: solid 1px $gray4;
			box-shadow: 0 0 10px 1px rgba(black, .1);
			border-radius: .1rem;
			z-index: 999;
			transform: translate(-50%, 0%);
			& .btns_tipo_busca{
				display: inline-block;
				vertical-align: middle;
				width: 20%;
				padding-right: 2rem;
				& .botoes{
					display: block;
					width: 100%;
					& p{
						font-weight: 600;
						margin: 0;
					}
					& button{
						width: 100%;
						display: inline-block;
						background-color: white;
						border: solid 1px $gray4;
						border-radius: .1rem;
						font-size: 1.5rem;
						color: $colorPrimary;
						font-weight: 600;
						padding: .5rem;
						margin: 0;
						cursor: pointer;
						transition: all .15s;
					}
				}
			}
	
			& .busca-filtro{
				width: 80%;
				display: inline-block;
				vertical-align: middle;
				& .bloco_busca{
					padding: 0;
					margin: 0;
					text-align: center;
					display: block;
					width: 100%;
					& .filtros_busca_home{
						width: 40%;
						display: inline-block;
						& p{
							font-weight: 600;
							margin: 0;
						}
					}
					& .filtros_busca_home2{
						width: 40%;
						display: inline-block;
						& p{
							color: $gray2;
							font-weight: 600;
							margin: 0;
						}
					}
					& .filtros_busca_home_input{
						width: 20%;
						display: inline-block;
						
						& p{
							color: $gray2;
							font-weight: 600;
							margin: 0;
						}
						& .btn-home{
							padding: 0.5rem;
							border-radius: 0.2rem;
							width: 100%;
							display: inline-block;
							vertical-align: middle;
							background-color: $colorPrimary;
							color: white;
							border: solid 1px #C3C3C3;
							font-size: 1rem;
							font-weight: 600;
							cursor: pointer;
							transition: all 0.25s;
							&:hover{
								background-color: darken($colorPrimary, 5);
							}
						}
					}
					& .filtros_busca_home_input2{
						width: 20%;
						display: inline-block;
						& p{
							color: $gray2;
							font-weight: 600;
							margin: 0;
						}
					}
					
				}
			}
		}
	}

	.headerpc {
		display: block;
		width: 100%;
		position: relative;

		& .superior {
			display: block;
			width: 100%;
			color: #ffffff;
			z-index: 3;

			/* GRUPO HEADER */
			& .logo {
				display: inline-block;
				margin-left: 0;
				@include border-box;
				vertical-align: middle;
				width: 12%;
				padding: .5rem 0;
				& .img{
					width: 100%;
					display: block;
					
					& img {
						display: block;
						width: 100%;
					}
				}
			}
		}
	}
}

@media all and (max-width: 1023px){

	#busca_header{
		position: relative;
		display: block;
		width: 100%;
		transition: all 0.2s;
    	z-index: 99999;
		& .busca_header_class{
			position: absolute;
			top: 0;
			left: 50%;
			background-color: white;
			display: block;
			width: 90%;
			padding: 2rem 1rem;
			text-align: center;
			border: solid 1px $gray4;
			box-shadow: 0 0 10px 1px rgba(black, .1);
			border-radius: .1rem;
			z-index: 99;
			transform: translate(-50%, 0%);
			& .btns_tipo_busca{
				display: block;
				width: 100%;
				padding: 0 4rem;
				& .botoes{
					display: block;
					width: 100%;
					& p{
						font-weight: 600;
						margin: 0;
					}
					& button{
						width: 100%;
						display: block;
						background-color: white;
						border: solid 1px $gray4;
						border-radius: .1rem;
						font-size: 1.5rem;
						color: $colorPrimary;
						font-weight: 600;
						padding: .5rem 1rem;
						margin: 0;
						cursor: pointer;
						transition: all .15s;
					}
				}
			}
	
			& .busca-filtro{
				width: 90%;
				display: block;
				& .bloco_busca{
					padding: 0;
					margin: 0;
					text-align: center;
					display: block;
					width: 100%;
					& .filtros_busca_home{
						width: 100%;
						display: block;
						margin-bottom: 1rem;
						& p{
							font-weight: 600;
							margin: 0;
						}
					}
					& .filtros_busca_home2{
						width: 100%;
						display: block;
						margin-bottom: 1rem;
						& p{
							color: $gray2;
							font-weight: 600;
							margin: 0;
						}
					}
					& .filtros_busca_home_input{
						width: 100%;
						display: block;
						margin-bottom: 0;
						& p{
							color: $gray2;
							font-weight: 600;
							margin: 0;
						}
						& .btn-home{
							padding: 0.5rem;
							border-radius: 0.2rem;
							width: 100%;
							display: inline-block;
							vertical-align: middle;
							background-color: $colorPrimary;
							color: white;
							border: solid 1px #C3C3C3;
							font-size: 1rem;
							font-weight: 600;
							cursor: pointer;
							transition: all 0.25s;
							&:hover{
								background-color: darken($colorPrimary, 5);
							}
						}
					}
					& .filtros_busca_home_input2{
						width: 100%;
						display: block;
						margin-bottom: 1rem;
						& p{
							color: $gray2;
							font-weight: 600;
							margin: 0;
						}
					}
					
				}
			}
		}
	}
	
	.headerindex {
		display: block;
		width: 100%;
		padding: 0;
		@include border-box;
		z-index: 2;
	}
	
	.headerpc{
		
		& .logo{
			padding: 0.3rem 0 0 0 !important;
		}
	}
}
