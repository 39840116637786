.pd_busca{
	padding-right: 1rem !important;
}

.title_condomi{
	text-align: center;
	& h1{
		font-size: 2.3rem;
		font-weight: 600;
		color: $gray1;
	}
	
	& h2{
		font-size: 2.3rem;
		font-weight: 600;
		color: $gray1;
	}

	& .under_p{
		text-align: center;
		width: 4.5rem;
		height: 3px;
		margin: auto;
		background-color: $colorPrimary;
	}
}

.title_condomi2{
	text-align: center;
	
	& h2{
		font-size: 1.75rem;
		font-weight: 600;
		color: $gray1;
	}

	& .under_p{
		text-align: center;
		width: 4.5rem;
		height: 3px;
		margin: auto;
		background-color: $colorPrimary;
	}
}

.section_servicos{
	width: 100%;
	display: block;
	background-color: $gray1;
	text-align: center;
	background-image: url('/jnh/header/texture.jpg');
	background-position: center;
	background-size: cover;
	background-repeat: no-repeat;

	& .margin_pc{
		margin: 0 4rem;
	}

	& a{
		width: 28%;
		display: inline-block;
		vertical-align: top;
		text-align: center;
		position: relative;

		& .bloco_servico {
			display: block;
			position: relative;
			width: 100%;
			background-color: white;
			border: solid 1px $colorPrimary;
			border-radius: .1rem;
			padding: 1rem;
			z-index: 0;
			overflow: hidden;
	
			& .img{
				width: 3rem;
				display: block;
				margin: 0;
				& img{
					text-align: left;
					width: 100%;
					transition: all .2s ease-in-out;
				}
			}

			& h2{
				color: $gray1;
				font-size: 1.1rem;
				font-weight: 600;
				transition: all .3s;
				text-align: left;
			}

			& p{
				padding: 0;
				color: $gray3;
				font-size: .9rem;
				font-weight: 400;
				transition: all .3s;
				text-align: left;
			}
		
			&::before {
				content: "";
				position: absolute;
				z-index: -1;
				bottom: 0.7rem;
				left: 1rem;
				background: $colorPrimary;
				height: 3px;
				width: 20%;
				border-radius: .1rem;
				transform: scale(1);
				transform-origin: 50% 50%;
				transition: 0.25s ease-in-out;
			}
		}

		&:hover{
			& .bloco_servico{
				& .img{
					& img{
						transform: scale(1.1);
					}
				}

				&::before {
					width: 90%;
				}
			}
		}
	}
}

.bg_busca_interna{
	background-color: white;
}

#ux_to_top{
	position: relative;
	top: -100px;
	left: -60%;
	z-index: 999999;
}

.botao_voltar{
	background-color: $colorPrimary;
	border: none;
	border-radius: .1rem;
	padding: .3rem 1rem;
	color: white !important;
	font-size: 1rem;
	font-weight: 500;
	cursor: pointer;
}


@media all and (max-width: 1023px){

	.pd_busca{
		padding-right: 0 !important;
	}

	.secao_categ{
		display: block;
		width: 100%;
		& .title_secao_categ{
			text-align: center;
			& h1{
				font-size: 2rem;
				font-weight: 600;
				color: $colorTerciary;
			}
			& p{
				font-size: 1rem;
				font-weight: 400;
				color: $gray3;
				text-align: center;
			}
			& .under_p{
				text-align: center;
				width: 4.5rem;
				height: 4px;
				margin: auto;
				background-color: $colorPrimary;
			}
		}

		& .trindade_categ{
			display: block;
			width: 100%;

			& .bloco{
				width: 100%;
				height: 12.5rem;
				display: block;
				@include border-box;
				padding: 1rem;
				position: relative;
				overflow: hidden;
				border-radius: .1rem;

				& .fade{
					position: absolute;
					top: 0;
					left: 0;
					width: 100%;
					height: 100%;
					z-index: 999;
					background: linear-gradient(to top, rgba(black, .6), rgba(black, .1));
				}

				& img{
					width: 100%;
					display: block;
					position: absolute;
					top: 50%;
					left: 50%;
					z-index: 99;
					transform: translate(-50%, -50%);
					transition: all .3s;
				}

				& p{
					font-size: 1.8rem;
					font-weight: 600;
					color: white;
					position: absolute;
					bottom: 1rem;
					left: 1.5rem;
					z-index: 9999;
				}

				&:hover{
					& img{
						transform: translate(-50%, -50%) scale(1.1);
					}
					& .fade{
						background: linear-gradient(to top, rgba(black, .4), rgba(black, 0));
					}
				}
			}
		}
	}

	.title_condomi{
		text-align: center;
		& h2{
			font-size: 2rem;
			font-weight: 600;
			color: $gray1;
		}
		& p{
			font-size: 1rem;
			font-weight: 400;
			color: $gray3;
			text-align: center;
		}
		& .under_p{
			text-align: center;
			width: 4.5rem;
			height: 3px;
			margin: auto;
			background-color: $colorPrimary;
		}
	}

	.section_servicos{
		width: 100%;
		display: block;
		background-color: $colorTerciary;
		text-align: center;
		background-image: url('/jnh/header/texture.jpg');
		background-position: center;
		background-size: cover;
		background-repeat: no-repeat;
	
		& .margin_pc{
			margin: 0;
		}
	
		& a{
			width: 100%;
			display: block;
			text-align: center;
			position: relative;
	
			& .bloco_servico {
				display: block;
				position: relative;
				width: 100%;
				background-color: white;
				border: solid 1px white;
				border-radius: .1rem;
				padding: 1rem;
				z-index: 0;
				overflow: hidden;
		
				& .img{
					width: 3rem;
					display: block;
					margin: 0;
					& img{
						text-align: left;
						width: 100%;
						transition: all .2s ease-in-out;
					}
				}
	
				& h2{
					color: $gray1;
					font-size: 1.1rem;
					font-weight: 600;
					transition: all .3s;
					text-align: left;
				}
	
				& p{
					padding: 0;
					color: $gray3;
					font-size: .9rem;
					font-weight: 400;
					transition: all .3s;
					text-align: left;
				}
			
				&::before {
					content: "";
					position: absolute;
					z-index: -1;
					bottom: 0.7rem;
					left: 1rem;
					background: $colorPrimary;
					height: 3px;
					width: 20%;
					border-radius: .1rem;
					transform: scale(1);
					transform-origin: 50% 50%;
					transition: 0.25s ease-in-out;
				}
			}
	
			&:hover{
				& .bloco_servico{
					& .img{
						& img{
							transform: scale(1.1);
						}
					}
	
					&::before {
						width: 90%;
					}
				}
			}
		}
	}
}

@media all and (max-width: 1023px){
	.page-wrap{
		display: block;
	}
	.page-wrap .width-50, .main-content, .main-content2{
		width: 100%;
	}
	#ux_to_top{
		left: 20%;
		top: -80px;
	}
}