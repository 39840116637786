.busca {
	background-color: white;
	display: block;
	width: 80%;
	padding: 1.5rem;
	text-align: center;
	border: solid 1px $gray5;
	box-shadow: 0 0 10px 1px rgba(black, .1);
	border-radius: .1rem;

	& .btns_tipo_busca{
		display: block;
		width: 100%;
		& .botoes{
			display: block;
			width: 100%;
			& button{
				background-color: white;
				border: solid 1px $colorTerciary;
				border-radius: .1rem;
				font-size: 1.5rem;
				color: $gray1;
				font-weight: 600;
				padding: .5rem 1.5rem;
				margin: 0 .5rem;
				cursor: pointer;
				transition: all .15s;
			}
		}
	}

	& .busca_cond{
		width: 100%;
		display: block;
		& .btns_Cond{
			width: 50%;
			display: block;
			margin: auto;
			& a{
				& p{
					text-align: center;
					font-size: 1.3rem;
					font-weight: 400;
					border: solid 1px $gray4;
					border-radius: .1rem;
					padding: 1rem .5rem;
				}
				& .p2{
					background-color: $colorPrimary;
					color: white;
					text-align: center;
					font-size: 1.3rem;
					font-weight: 400;
					border-radius: .1rem;
					padding: 1rem .5rem;
				}
			}
		}
	}

	& .busca-filtro{
		width: 100%;
		display: block;
		& .bloco_busca{
			padding: 0;
			margin: 0;
			text-align: center;
			display: block;
			width: 100%;
			& .filtros_busca_home{
				width: 35%;
				display: inline-block;
				padding: 0;
				& p{
					font-weight: 600;
					margin: 0;
				}
			}
			& .filtros_busca_home2{
				width: 35%;
				display: inline-block;
				padding: 0;
				& p{
					font-weight: 600;
					margin: 0;
				}
			}
			& .filtros_busca_home_input{
				width: 15%;
				display: inline-block;
				padding: 0;
			}
			& .btn-home{
				padding: .5rem 0;
				border-radius: .1rem;
				width: 100%;
				display: inline-block;
				vertical-align: middle;
				background-color: $colorPrimary;
				color: white;
				font-size: 1rem;
				font-weight: 600;
				border: none;
				cursor: pointer;
				transition: all .2s;
				&:hover{
					background-color: darken($colorPrimary, 5);
				}
				
			}
		}
	}
}

.botao_buscar_int{
	text-align: center;
    padding: 0.5rem 0;
    border: solid 1px $colorPrimary;
    border-radius: .1rem;
    margin-top: 0.15rem;
    background-color: $colorPrimary;
    color: white;
    font-size: 1rem;
    font-weight: 600;
    cursor: pointer;
    line-height: normal;
    transition: all 0.2s;
	&:hover{
		background-color: darken($colorPrimary, 5);
	}
}

.botao_limpar_int{
	text-align: center;
	padding: .5rem 0;
	border: solid 1px $colorPrimary;
	border-radius: .1rem;
	margin-top: .15rem;
	background-color: white;
	color: $colorPrimary;
	font-size: 1rem;
	font-weight: 600;
	cursor: pointer;
    line-height: normal;
	transition: all .2s;
	&:hover{
		background-color: darken(white, 2);
	}
}

.busca_container-fixed{
	display: block;
	position: fixed;
	z-index: 10;
}

@media all and (max-width: 1023px){
	#busca_container {
		display: block;
		width: 100%;
		overflow: auto;
		position: fixed;
		top: 0;
		left: 0;
		height: 100vh;
		@include border-box;
		z-index: 99999;

		& .busca_btn_close {
			position: absolute;
			top: 1rem;
			right: 3rem;
			line-height: inherit;
		}
	}

	.busca {
		background-color: white;
		display: block;
		width: 100%;
		padding: 1.5rem;
		text-align: center;
		border: solid 1px $gray5;
		box-shadow: 0 0 10px 1px rgba(black, .1);
		border-radius: .1rem;

		& .btns_tipo_busca{
			display: block;
			width: 100%;
			& .botoes{
				display: block;
				width: 100%;
				padding: 0;
				& button{
					width: 100%;
					background-color: white;
					border: solid 1px $colorTerciary;
					border-radius: .1rem;
					font-size: 1rem;
					text-transform: uppercase;
					color: $gray1;
					font-weight: 600;
					padding: .5rem 1rem;
					margin: 0;
					cursor: pointer;
					transition: all .15s;
				}
			}
		}

		& .busca_cond{
			width: 100%;
			display: block;
			padding: 0 1rem;
			& .btns_Cond{
				width: 100%;
				display: block;
				margin: auto;
				& a{
					& p{
						text-align: center;
						font-size: 1.3rem;
						font-weight: 400;
						border: solid 1px $gray4;
						border-radius: .1rem;
						padding: 1rem .5rem;
					}
					& .p2{
						background-color: $colorPrimary;
						color: white;
						text-align: center;
						font-size: 1.3rem;
						font-weight: 400;
						border-radius: .1rem;
						padding: 1rem .5rem;
					}
				}
			}
		}

		& .busca-filtro{
			width: 100%;
			display: block;
			& .bloco_busca{
				padding: 0;
				margin: 0;
				text-align: center;
				display: block;
				width: 100%;
				& .filtros_busca_home{
					width: 100%;
					display: block;
					padding: 0;
					& p{
						font-weight: 600;
						margin: 0;
					}
				}
				& .filtros_busca_home2{
					width: 100%;
					display: block;
					padding: 0;
					& p{
						font-weight: 600;
						margin: 0;
					}
				}
				& .filtros_busca_home_input{
					width: 100%;
					display: block;
					padding: 0;
				}
				& .btn-home{
					padding: 1rem .5rem;
					border-radius: .1rem;
					width: 100%;
					display: inline-block;
					vertical-align: middle;
					background-color: $colorPrimary;
					color: white;
					font-size: 1.2rem;
					font-weight: 600;
					border: none;
					cursor: pointer;
					&:hover{
						background-color: darken($colorPrimary, 5);
					}
					
				}
			}
		}
	}
}
