.miniatura-imovel-video{
	display: inline-block;
	vertical-align: top;
	margin-bottom: 2rem;
	& .miniatura-imovel-img-video{
		border-radius: .2rem .2rem 0 0;
		display: block;
		width: 100%;
		overflow: hidden;
		background-color: $gray1;
		position: relative;
		user-select: none;

		& img{
			width: 100%;
			display: block;
		}

		& .miniatura-video-player{
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
			& i{
				font-size: 4rem;
				color: white;
				font-weight: 500;
				text-shadow: 0 1px black;
			}
		}
	}

	& .miniatura-imovel-detalhe{
		border: solid 1px $gray4;
		border-radius: 0 0 .2rem .2rem;
		padding: 1rem;

		& p{
			display: inline-block;
			width: 70%;
			text-align: left;
			color: $gray1;
			font-size: 1.1rem;
			font-weight: 500;
		}

		& button{
			display: inline-block;
			width: 30%;
			text-align: center;
			cursor: pointer;
			border: solid 1px $colorPrimary;
			background-color: $colorPrimary;
			color: white;
			font-size: 1rem;
			font-weight: 500;
			border-radius: .1rem;
			padding: .5rem 0;
			transition: all .2s;
			&:hover{
				background-color: darken($colorPrimary, 5);
			}
		}
	}
}